/**
 * @file front_end/src/components/NewRequest.js
 * Add a new request to the queue
 */

// usual React imports
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import { Toast } from 'react-bootstrap';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Col,
  FormFeedback,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
} from 'reactstrap';

// to get the list of papers and their prices
import { getLabels } from '../services/general';

// to access the base url (dynamic, either localhost or from heroku)
import config from '../auth_config.json';

// need to connect to backend
import axios from 'axios';

// for generating hashes
import md5 from 'md5';

import './NewRequest.css';

const NewRequest = () => {
  // will need to get the access token to send to the backend
  // this token has info about this user (including email)
  const { getTokenSilently, user } = useAuth0();

  // state variables
  const [payByAccount, setPayByAccount] = useState(false);
  const [accountValid, setAccountValid] = useState(false);
  const [subAccountValid, setSubAccountValid] = useState(false);
  const [paperTypes, setPaperTypes] = useState([[]]);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    status: 'default',
    description: 'default',
  });

  const labels = getLabels();
  const [modal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submitedRequest, setSubmitedRequest] = useState({});

  //used for hiding/showing the spinner
  const [spinnerHidden, setSpinnerHidden] = useState(true);

  // the contents of the form's fields are stored here
  const state = {
    filepath: '',
    paper: '',
    payByAccount: 'false',
    account: 0,
    subaccount: 0,
    label: '',
  };

  // execute one time, on first component render
  useEffect(() => {
    // run the query for the database
    getInkConfig();
  }, []);

  /**
   * sets the paperType for the drop downs
   */
  const getInkConfig = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/jobs', send authentication token
      const response = await axios.get(`${config.BASE_URL}/api/inkconfig/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // what do we want to showToast to the user
      let inkConfig = response.data.results[0];
      let pt = [
        ['Bond', inkConfig.bond],
        ['Satin Photo', inkConfig.satinPhoto],
        ['Matte Photo', inkConfig.mattePhoto],
        ['Self Adhesive Poly', inkConfig.selfAdhesivePoly],
        ['Photo Tex', inkConfig.photoTex],
      ];
      setPaperTypes(pt);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getInkConfig() caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * when a radio button is clicked, this function is triggered
   * and the state for payByAccount is set ... which triggers a
   * rerender, so that we can display/hide the account boxes
   * @param input the radio button object (input.currentTarget.value incates
   * which option is selected)
   */
  const handleRadioChange = (input) => {
    // set both the state.payByAccount local variable and also
    // set the useState payByAccount variable to trigger a rerender
    if (input.currentTarget.value === 'true') {
      state.payByAccount = 'true';
      setPayByAccount(true);
    } else {
      state.payByAccount = 'false';
      setPayByAccount(false);
    }
  };

  /**
   * handle when the account number is entered (as text), performing input
   * validation:  the number must be 5 digits long
   * @param input text box object where input.target.value is the contents
   */
  const handleAccountChange = (input) => {
    // We need the input to be only integers and exactly 5 digits long
    // source: https://stackoverflow.com/questions/40157692/how-to-validated-input-type-text-with-numeric-in-react
    const num = input.target.value;
    if (num.length === 5 && /^(\s*|\d+)$/.test(num)) {
      setAccountValid(true);
      state.account = num;
    } else {
      setAccountValid(false);
      state.account = 0;
    }
  };

  /**
   * handle when the subaccount number is entered (as text), performing input
   * validation:  the number must be 4 digits long
   * @param input text box object where input.target.value is the contents
   */
  const handleSubAccountChange = (input) => {
    // similar to handleAccountChange
    const num = input.target.value;
    if (num.length === 4 && /^(\s*|\d+)$/.test(num)) {
      setSubAccountValid(true);
      state.subAccount = num;
    } else {
      setSubAccountValid(false);
      state.subAccount = 0;
    }
  };

  /**
   * Show/hide modal
   */
  const toggleModal = () => {
    setModal(!modal);
  };

  /**
   * a function that creates a new job in the database
   * @param object job object to be create w/ and id field
   */
  const handleSubmit = async () => {
    try {
      const token = await getTokenSilently();
      // make the button disabled, so that they can't click it twice, and start spinner
      setDisabled(true);
      setSpinnerHidden(false);

      const job = buildJobObj();
      const uploadEl = document.querySelector('.file-upload');
      if(uploadEl.files[0].type !== 'application/pdf') {
        setToastMsg({
          status: 'Error',
          description: 'Please Upload a .pdf file...',
        });
        setShowToast(true);
        setSpinnerHidden(true);
        setDisabled(false);
        return;
      }
      const file = generateFile(user.email, uploadEl.files[0]);
      
      job.filename = file.name;

      let formData = new FormData();
      formData.append('image', file);

      // upload the file to AWS
      const uploadRes = await axios({
        method: 'post',
        url: `${config.BASE_URL}/api/files/upload`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      // save job in the database
      const datbaseRes = await axios.post(
        `${config.BASE_URL}/api/jobs`,
        {
          job,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSubmitedRequest(datbaseRes.data.results);
      toggleModal();

      // stop spinner
      setSpinnerHidden(true);
      // TODO:  WOuld be nice to redirect to job queue here
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('Caught error:' + error);
    }
  };

  /**
   * when the user submits the form, an email is sent with subject/body
   * from the form
   */
  const handleEmailToManager = async () => {
    const token = await getTokenSilently();

    // construct the send to
    const sendTo = 'jjcoy@anderson.edu';
    const body = 'New poster request from ' + user.email;

    // connect to the endpoint and send an email
    const response = await axios.post(
      `${config.BASE_URL}/api/sendEmail`,
      {
        name: 'Nic Media Poster',
        email: `${sendTo}`,
        subject: 'New Poster Request',
        messageHTML: `${body}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // after we send the email, let the user know if it succeeded
    let statusCheck = response.data.gmail;
    if (statusCheck.substr(0, statusCheck.indexOf(' ')) === 'Success') {
      toggleModal();
    } else {
      alert('Oops, something went wrong. Please try again.');
    }
  };

  /**
   * deletes the request from the database
   */
  const handleCancelRequest = async () => {
    const token = await getTokenSilently();

    const response = await axios.delete(`${config.BASE_URL}/api/jobs/` + submitedRequest.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    toggleModal();
    setDisabled(false);
  };

  /**
   * sends confirmed request to email
   */
  const handleConfirmRequest = async () => {
    handleEmailToManager();
    setDisabled(false);
  };

  /**
   * builds a video from the input boxes
   * @return {object} the video to be created
   */
  const buildJobObj = () => {
    let job = {}; // new job object
    let inputs = document.querySelectorAll('.input'); // get all input boxes

    // iterate through each input and update job's meta data
    inputs.forEach((input) => {
      if (input.name !== 'paymentMethod') {
        job[input.id] = input.value;
      }
    });

    if (payByAccount) {
      job['paymentMethod'] = 'Account';
      job['accountDetails'] = job.accountNumber + '-' + job.accountSubNumber;
    } else {
      job['paymentMethod'] = 'Cash or Check';
      job['accountDetails'] = 'Cash or Check';
    }

    job['email'] = user.email;

    delete job.accountNumber;
    delete job.accountSubNumber;

    return job;
  };

  /**
   * builds a unique filename
   * @return {string} the filename to be created
   */
  const generateFile = (username, file) => {
    let filename = file.name;
    let id = generateId();

    filename = filename.split('.');
    filename[0] = md5(username + id);
    filename = filename.join('.');

    const renamedFile = new File([file], filename);
    return renamedFile;
  };

  /**
   * https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
   * returns a unique id
   * @return {string} the id
   */
  const generateId = () => {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, (c) => {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  return (
    <Form>
      <Container>
        {/* Allow uploading of the file */}
        <FormGroup row>
          <Label for="filename" sm={4}>
            File
          </Label>
          <Col xs={8}>
            <Input
              className="input file-upload"
              type="file"
              name="file"
              id="filename"
              accept=".pdf"
              onChange={(input) => (state.filepath = input.target.value)}
            />
          </Col>
        </FormGroup>
        {/* Choose the paper type */}
        <FormGroup row>
          <Label for="paperType" sm={4}>
            Select Paper Type
          </Label>
          <Col xs={8}>
            <Input
              className="input"
              type="select"
              name="select"
              id="paperType"
              onChange={(input) => (state.paper = input.target.value)}
            >
              {/* build the list of paper types and their prices */}
              {paperTypes.map((eachType) => {
                return (
                  <option key={eachType[0]} value={eachType[0]}>
                    {eachType[0] +
                      ':  $' +
                      (Math.round(eachType[1] * 100) / 100).toFixed(2) +
                      ' per square ft'}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="nickname" sm={4}>
            Enter Nickname
          </Label>
          <Col xs={8}>
            <Input
              className="input"
              type="input"
              name="nickname"
              id="nickname"
              placeholder="Enter a nickname..."
            />
          </Col>
        </FormGroup>
        {/* Choose to pay by account of cash/check */}
        <FormGroup row>
          <Label for="paymentMethod" sm={4}>
            Payment Method
          </Label>
          <Col xs={8}>
            <div className="align-radio-btn">
              <Input
                className="input"
                type="radio"
                name="paymentMethod"
                value="false"
                onChange={handleRadioChange}
              />{' '}
              Pay by cash or check at pickup
            </div>
            <div className="align-radio-btn">
              <Input
                className="input"
                type="radio"
                name="paymentMethod"
                value="true"
                onChange={handleRadioChange}
              />{' '}
              Use Department Account
            </div>
          </Col>
        </FormGroup>
        {/* If paying by account, enter account and subaccount */}
        {payByAccount === false ? null : (
          <FormGroup row>
            <Label for="accountNumber" sm={4}>
              AU Department Number
            </Label>
            <Col xs={8}>
              <Input
                className="input"
                type="text"
                name="accountNumber"
                id="accountNumber"
                placeholder="Enter 5 digit department number"
                invalid={accountValid === false}
                onChange={handleAccountChange}
              />
              <FormFeedback>Department number must be 5 digits</FormFeedback>
            </Col>
          </FormGroup>
        )}
        {payByAccount === false ? null : (
          <FormGroup row>
            <Label for="accountSubNumber" sm={4}>
              Budget Line
            </Label>
            <Col xs={8}>
              <Input
                className="input"
                type="text"
                name="accountSubNumber"
                id="accountSubNumber"
                placeholder="Enter 4 digit budget line"
                invalid={subAccountValid === false}
                onChange={handleSubAccountChange}
              />
              <FormFeedback>Budget line must be 4 digits</FormFeedback>
            </Col>
          </FormGroup>
        )}
        {/* Select a label for this request, if applicable */}
        <FormGroup row>
          <Label for="requestType" sm={4}>
            Type of Request
          </Label>
          <Col xs={8}>
            <Input
              className="input"
              type="select"
              name="select"
              id="requestType"
              onChange={(input) => (state.label = input.target.value)}
            >
              {/* build the list of paper types and their prices */}
              {labels.map((eachLabel) => {
                return <option key={eachLabel}>{eachLabel}</option>;
              })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Button onClick={handleSubmit} disabled={disabled} className="Submit">
            Submit Request
          </Button>
          <div className="spinner-container"><Spinner className="spinner" color="danger" hidden={spinnerHidden} /></div>
        </FormGroup>
      </Container>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Request Sent</ModalHeader>
        <ModalBody>
          <h2>Price: {parseFloat(submitedRequest.estimateCost).toFixed(2)}</h2>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmRequest} className="modal-close">
            Confirm
          </Button>
          <Button color="secondary" onClick={handleCancelRequest}>
            Cancel Request
          </Button>
        </ModalFooter>
      </Modal>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className="toast-box"
      >
        <Toast.Header>
          <strong className="mr-auto">{toastMsg.status}</strong>
        </Toast.Header>
        <Toast.Body>{toastMsg.description}</Toast.Body>
      </Toast>
    </Form>
  );
};

export default NewRequest;
