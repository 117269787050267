// src/App.js

// standard react components
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAuth0 } from './react-auth0-wrapper';

// This file provides the PrivateRouter functionality in the Switch below
import PrivateRoute from './PrivateRoute';

// css file and other assets needed
import './App.css';

// bring in other components
import NavBar from './components/NavBar';
import Home from './components/Home';
import AboutPage from './components/AboutPage';
import JobQueue from './components/JobQueue';
import JobQueueForSpecificUser from './components/JobQueueForSpecificUser';
import TestGetPDFDimensions from './components/TestGetPDFDimensions';
import TestGetImage from './components/TestGetImage';
import NewRequest from './components/NewRequest';
import InkConfig from './components/InkConfig';
import PleaseLogin from './components/PleaseLogin';

/*
 * This is the App component, referenced from index.js
 */
const App = () => {
  // get information from Auth0, pulling in specifically the
  const { loading } = useAuth0();
  if (loading) {
    return <div>Loading...</div>;
  }

  // if we need the token for debugging, uncomment
  // const { loading, isAuthenticated, getTokenSilently } = useAuth0();
  // if (isAuthenticated) {
  //   getTokenSilently().then(token => console.log(token));
  // }

  /* ready to create the HTML for this section (referenced from index.js)
   * Note that JSX comments, inside the return(), must show up
   * inside {} using star-slash style comments */
  return (
    <div className="App">
      {/* The BrowserRouter package gives our Single Page Application the
       * ability to use routes in the URL.
       */}
      <BrowserRouter>
        {/* The NavBar component will be shown in a header, for all pages */}
        <header>
          <NavBar />
        </header>

        {/* Switch will connect the route in the URL to the component
         * This is sort of like the body of the page.  The code below
         * will choose one of the following items to display, depending on the
         * path in the URL.
         */}
        <Switch>
          {/* "Routes" can be accessed whether or not user is logged in */}
          <Route path="/" exact component={Home} />
          <Route path="/about" component={AboutPage} />
          <Route path="/samplepdf" component={TestGetPDFDimensions} />
          <Route path="/samplepng" compnonent={TestGetImage} />
          {/* PrivateRoutes can only be accessed when logged in,
           * this functionality is provided in the file src/PrivateRoute.js */}
          <PrivateRoute path="/queue" component={JobQueue} />
          <PrivateRoute path="/inkconfig" component={InkConfig} />
          <PrivateRoute path="/newrequest" component={NewRequest} />
          <PrivateRoute
            path="/myrequests"
            component={JobQueueForSpecificUser}
          />
          {/* Fallback route for all unknown URLs */}
          <Route component={PleaseLogin} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
