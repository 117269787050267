// src/components/Inkconfig.js

// standard imports
import { useAuth0 } from '../react-auth0-wrapper';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Label, Input } from 'reactstrap';
import { Toast } from 'react-bootstrap';
import './JobQueue.css';
import './InkConfig.css';

// to access the base url (dynamic, either localhost or from heroku)
import config from '../auth_config.json';

const Inkconfig = () => {
  // will need to get the access token to send to the backend
  // this token has info about this user (including email)
  const { getTokenSilently } = useAuth0();

  // the current ink config
  const [inkConfig, setInkConfig] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    status: 'default',
    description: 'default',
  });

  // execute one time, on first component render
  useEffect(() => {
    // run the query for the database
    getInkConfig();
  }, []);

  /**
   * a function to connect to the backend
   * gets all InkConfig
   */
  const getInkConfig = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      console.log('I see ', config.BASE_URL);

      // connect to the endpoint 'api/jobs', send authentication token
      const response = await axios.get(`${config.BASE_URL}/api/inkconfig/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // what do we want to showToast to the user
      setInkConfig(response.data.results[0]);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('Inkconfig caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * a function that updates a InkConfig in the database
   * @param {object} InkConfig the InkConfig object to be updated
   */
  const updateInkConfig = async (inkConfig) => {
    try {
      const token = await getTokenSilently();

      // connect to the endpoint "/api/jobs"
      await axios.put(
        '/api/inkconfig',
        {
          inkConfig,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToastMsg({
        status: 'Success',
        description: 'Ink config settings updated.',
      });
      setShowToast(true);
      getInkConfig();
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('Caught error:' + error);
      setToastMsg({
        status: 'Error',
        description: 'Ink config settings failed to updated check log.',
      });
      setShowToast(true);
    }
  };

  /**
   * handles when the update btn is clicked
   */
  const handleUpdateClick = () => {
    const obj = buildInkConfigObj();
    updateInkConfig(obj);
  };

  /**
   * builds a InkConfig object from the input boxes
   * with the values on the page
   * @return {object} the InkConfig obj to be updated
   */
  const buildInkConfigObj = () => {
    let inkConfigObj = {}; // new object
    let inputs = document.querySelectorAll('.input'); // get all input boxes

    // iterate through each input and update job's meta data
    inputs.forEach((input) => {
      inkConfigObj[input.id] = parseFloat(input.value);
    });

    inkConfigObj = Object.assign(inkConfig, inkConfigObj);

    //these fields are handled by the database we dont need them
    delete inkConfigObj.createdAt;
    delete inkConfigObj.updatedAt;

    return inkConfigObj;
  };

  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // Displayed upon first rendering of application
    <div>
      <div className="input-group">
        <div className="input-container">
          <Label className="input-lable">PPI</Label>
          <Input
            className="input"
            type="input"
            id="ppi"
            placeholder="Enter a ppi price..."
            defaultValue={inkConfig.ppi}
          />
        </div>
        <div className="input-container">
          <Label className="input-lable">Bond Price</Label>
          <Input
            className="input"
            type="input"
            id="bond"
            placeholder="Enter a bond price..."
            defaultValue={inkConfig.bond}
          />
        </div>
      </div>
      <div className="input-group">
        <div className="input-container">
          <Label className="input-lable">Satin Photo Price</Label>
          <Input
            className="input"
            type="input"
            id="satinPhoto"
            placeholder="Enter a satin photo price..."
            defaultValue={inkConfig.satinPhoto}
          />
        </div>
        <div className="input-container">
          <Label className="input-lable">Matte Photo Price</Label>
          <Input
            className="input"
            type="input"
            id="mattePhoto"
            placeholder="Enter a matte photo price..."
            defaultValue={inkConfig.mattePhoto}
          />
        </div>
      </div>
      <div className="input-group">
        <div className="input-container">
          <Label className="input-lable">Self Adhesive Poly Price</Label>
          <Input
            className="input"
            type="input"
            id="selfAdhesivePoly"
            placeholder="Enter a self adhesive poly price..."
            defaultValue={inkConfig.selfAdhesivePoly}
          />
        </div>
        <div className="input-container">
          <Label className="input-lable">Photo Tex Price</Label>
          <Input
            className="input"
            type="input"
            id="photoTex"
            placeholder="Enter a photo tex price..."
            defaultValue={inkConfig.photoTex}
          />
        </div>
      </div>
      <div className="input-group">
        <div className="input-container">
          <Label className="input-lable">Ink Price</Label>
          <Input
            className="input"
            type="input"
            id="ink"
            placeholder="Enter a ink price..."
            defaultValue={inkConfig.ink}
          />
        </div>
        <div className="input-container">
          <Button
            className="update-btn"
            color="primary"
            onClick={handleUpdateClick}
          >
            Update
          </Button>
        </div>
      </div>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className="toast-box"
      >
        <Toast.Header>
          <strong className="mr-auto">{toastMsg.status}</strong>
        </Toast.Header>
        <Toast.Body>{toastMsg.description}</Toast.Body>
      </Toast>
    </div>
  );
};

export default Inkconfig;
