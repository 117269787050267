// front_end/src/components/TestGetPDFDimensions.js

// standard react component
import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// test PDF to work from
import samplePDF from '../assets/CsCapstoneII-Poster-Fall2019.pdf';
import samplePDF2 from '../assets/health.pdf';

// component to work on gathering the PDF dimensions from a file
const TestGetPDFDimensions = () => {
  // state variables for storing information about the pdf
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  // execute once on page load
  useEffect(() => {
    // get window size in pixels
    setWindowWidth(window.innerWidth);
    // reduce the window height to account for the navbar and buttons
    setWindowHeight(window.innerHeight * 0.7);
  }, []);

  /**
   * after the react-pdf package loads the pdf, it calls this function
   * @param numPages the number of pages in this document
   */
  const onPDFDocumentLoadSuccess = ({ numPages }) => {
    // set the number of pages
    setNumPages(numPages);
    // start on the first page
    setPageNumber(1);
  };

  /**
   * after the react-pdf package loads a specific page in the pdf,
   * it calls this function
   * @param objInfo an object containing lots of information about the pdf
   */
  const onPageLoadSuccess = (objInfo) => {
    // these dimensions are in pixels
    setPageHeight(objInfo.originalHeight);
    setPageWidth(objInfo.originalWidth);
    // determine the scale needed to show the object
    let heightScale = windowHeight / objInfo.originalHeight;
    let widthScale = windowWidth / objInfo.originalWidth;
    // set the scale to the smaller of the two dimensions
    setScale(heightScale < widthScale ? heightScale : widthScale);
  };

  /**
   * function to move through pages of the PDF by an arbitrary offset
   * @param offset the number of pages to move
   */
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  /**
   * move to previous page in the pdf
   */
  const previousPage = () => {
    changePage(-1);
  };

  /**
   * move to next page in the pdf
   */
  const nextPage = () => {
    changePage(1);
  };

  // JSX for the item to display
  return (
    <div>
      <div className="pdfParent">
        {/* Document and Page are from the react-pdf package */}
        <Document file={samplePDF} onLoadSuccess={onPDFDocumentLoadSuccess}>
          <Page
            className="pdfPage"
            pageNumber={pageNumber}
            onLoadSuccess={onPageLoadSuccess}
            scale={scale}
          />
        </Document>
      </div>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
        <p>
          Height: {pageHeight}
          <br></br>
          Width: {pageWidth}
        </p>
      </div>
    </div>
  );
};

export default TestGetPDFDimensions;
