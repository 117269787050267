/**
 * @file front_end/src/components/JobQueueForSpecificUser.js
 * List all jobs in the queue
 */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from 'reactstrap';

// get css
import './JobQueue.css';

import DataTable from 'react-data-table-component';
import { useAuth0 } from '../react-auth0-wrapper';

// to access the base url (dynamic, either localhost or from heroku)
import config from '../auth_config.json';

// need to connect to backend
import axios from 'axios';

// import function from services file
import {
  formatDate,
  formatDateString,
  getLabels,
  getStatus,
  getPayments,
} from '../services/general';

// configure DataTable
// if we match the "selector" to the column header in the database,
// we can just use our response.data.results to fill the table information
// column formatting here: https://github.com/jbetancur/react-data-table-component#columns
const columns = [
  {
    name: 'User Email',
    selector: 'email',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Paper Type',
    selector: 'paperType',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Request Type',
    selector: 'requestType',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Latest Timestamp',
    selector: 'updatedAt',
    sortable: true,
    wrap: true,
    format: (row) => formatDate(row),
  },
];

// formating DataTable
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '1.01em',
      color: 'black',
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '5px',
      borderBottomStyle: 'solid',
    },
  },
  cells: {
    style: {
      wordBreak: 'break-word',
    },
  },
};

const JobQueueForSpecificUser = () => {
  // will need to get the access token to send to the backend
  // this token has info about this user (including email)
  const { getTokenSilently, user } = useAuth0();

  // state variables
  const [jobList, setJobList] = useState([]);
  const [modal, setModal] = useState(false);

  // whether or not to display the 3 modals from this page
  const [editModal, setEditModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [emailSentModal, setEmailSentModal] = useState(false);

  // which row is currently selected
  const [selectedRow, setSelectedRow] = useState({});
  const [paperTypes, setPaperTypes] = useState([[]]);

  const requestTypes = getLabels();
  const dropdownStatus = getStatus();
  const payments = getPayments();

  // used to disable send email button
  const [disabled, setDisabled] = useState(false);

  // execute one time, on first component render
  // execute everytime the "status" state is updated
  useEffect(() => {
    getJobListBasedOnStatus({ status }.status);
  }, [status]);

  // execute one time, on first component render
  useEffect(() => {
    // run the query for the database
    getInkConfig();
  }, []);

  /**
   * sets the paperType for the drop downs
   */
  const getInkConfig = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/jobs', send authentication token
      const response = await axios.get(`${config.BASE_URL}/api/inkconfig/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // what do we want to showToast to the user
      let inkConfig = response.data.results[0];
      let pt = [
        ['Bond', inkConfig.bond],
        ['Satin Photo', inkConfig.satinPhoto],
        ['Matte Photo', inkConfig.mattePhoto],
        ['Self Adhesive Poly', inkConfig.selfAdhesivePoly],
        ['Photo Tex', inkConfig.photoTex],
      ];
      setPaperTypes(pt);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getInkConfig() caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * a function that is triggered when the user clicks on a row
   * @param {*} row
   */
  const handleRowClick = (row) => {
    setSelectedRow(row);
    toggleEditModal();
  };

  /**
   * when the user submits the form, an email is sent with subject/body
   * from the form
   */
  const handleEmailSubmit = async () => {
    // get the token
    const token = await getTokenSilently();

    // make the button disabled, so that they can't click it twice
    setDisabled(true);

    // get all input boxes
    let inputs = document.querySelectorAll('.input');

    // construct the to line, so that the user also gets the email
    const toList = 'jjcoy@anderson.edu, ' + user.email;

    // connect to the endpoint and send an email
    const response = await axios.post(
      `${config.BASE_URL}/api/sendEmail`,
      {
        name: 'Nic Media App',
        email: `${toList}`,
        subject: `${'Update on job from ' + user.email}`,
        messageHTML: `${inputs[0].value}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // after we send the email, let the user know if it succeeded
    let statusCheck = response.data.gmail;
    if (statusCheck.substr(0, statusCheck.indexOf(' ')) === 'Success') {
      toggleEmailSentModal();
    } else {
      alert('Oops, something went wrong. Please try again.');
    }
  };

  /**
   * a function to connect to the backend
   * sets the state variable jobListWithSpecificStatus
   * @param statusParam the string indicating status, such as "submitted"
   */
  const getJobListBasedOnStatus = async (statusParam) => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/jobs', send authentication token
      const response = await axios.get(
        `${config.BASE_URL}/api/jobsForUser/` + user.email,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // what do we want to show to the user
      setJobList(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('JobQueue caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * toggles the edit job modal's display
   * if the modal is closing refetch the data
   */
  const toggleEditModal = () => {
    if (editModal) {
      // get the updated data for the page
      getJobListBasedOnStatus({ status }.status);
    }
    setEditModal(!editModal);
  };

  /**
   * toggles the contact modal's display
   */
  const toggleContactModal = () => {
    // turn off edit modal
    setEditModal(false);
    // reset submit button if we are closing the modal
    if (contactModal) {
      setDisabled(false);
    }
    // switch display for the contact modal
    setContactModal(!contactModal);
  };

  /**
   * toggles the contact modal's display
   */
  const toggleEmailSentModal = () => {
    // turn off contact modal
    setContactModal(false);
    // reset submit button if we are closing the modal
    if (emailSentModal) {
      setDisabled(false);
    }
    // switch display for the email sent modal
    setEmailSentModal(!emailSentModal);
  };

  /**
   * handles actions for when save is clicked
   */
  const handleEditModalBtnClick = async () => {
    const token = await getTokenSilently();
    let job = buildJobObj();
    await updateJob(job);
    toggleEditModal();
  };

  /**
   * builds a job from the input boxes
   * with the values in the edit modal's text boxes
   * @return {object} the job to be updated/created
   */
  const buildJobObj = () => {
    let job = {}; // new job object
    let inputs = document.querySelectorAll('.input'); // get all input boxes

    // iterate through each input and update job's meta data
    inputs.forEach((input) => {
      job[input.id] = input.value;
    });

    job = Object.assign(selectedRow, job);

    job.estimateCost = parseFloat(job.estimateCost);

    //these fields are handled by the database we dont need them
    delete job.createdAt;
    delete job.updatedAt;

    return job;
  };

  /**
   * a function that updates a job in the database
   * @param {object} job the job object to be updated
   */
  const updateJob = async (job) => {
    try {
      const token = await getTokenSilently();

      // connect to the endpoint "/api/jobs"
      await axios.put(
        `${config.BASE_URL}/api/jobs`,
        {
          job,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('Caught error:' + error);
    }
  };

  return (
    <div>
      {/* BEGIN ------------ Modal for Editing the Job's Request */}
      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Request</ModalHeader>
        <ModalBody>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Email</Label>
              <Input
                className="input"
                type="input"
                id="email"
                placeholder="Enter a email..."
                defaultValue={selectedRow.email}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Filename</Label>
              <Input
                className="input"
                type="input"
                id="filename"
                placeholder="Enter a filename..."
                defaultValue={selectedRow.filename}
                disabled
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Paper Type</Label>
              <Input
                className="input"
                type="select"
                id="paperType"
                placeholder="Enter a paper type..."
                defaultValue={selectedRow.paperType}
              >
                {/* build the list of paper types */}
                {paperTypes.map((eachType) => {
                  return <option key={eachType[0]}>{eachType[0]}</option>;
                })}
              </Input>
            </div>
            <div className="input-container">
              <Label className="input-lable">Request Type</Label>
              <Input
                className="input"
                type="select"
                id="requestType"
                placeholder="Enter a request type..."
                defaultValue={selectedRow.requestType}
              >
                {/* build the list of request types */}
                {requestTypes.map((eachRequest) => {
                  return <option key={eachRequest}>{eachRequest}</option>;
                })}
              </Input>
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Payment Method</Label>
              <Input
                className="input"
                type="select"
                id="paymentMethod"
                placeholder="Enter a payment method..."
                defaultValue={selectedRow.paymentMethod}
              >
                {/* build the list of payment types */}
                {payments.map((eachPayment) => {
                  return <option key={eachPayment}>{eachPayment}</option>;
                })}
              </Input>
            </div>
            <div className="input-container">
              <Label className="input-lable">Account Details</Label>
              <Input
                className="input"
                type="input"
                id="accountDetails"
                placeholder="Enter a Account Details..."
                defaultValue={selectedRow.accountDetails}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Estimate Cost</Label>
              <Input
                className="input"
                type="input"
                id="estimateCost"
                placeholder="Enter estimate cost..."
                defaultValue={parseFloat(selectedRow.estimateCost).toFixed(2)}
                disabled
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Nickname</Label>
              <Input
                className="input"
                type="input"
                id="nickname"
                placeholder="Enter a nickname..."
                defaultValue={selectedRow.nickname}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleContactModal}>
            Contact Print Manager
          </Button>
          <Button color="primary" onClick={handleEditModalBtnClick}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleEditModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* END ------------ Modal for Editing the Job's Request */}
      {/* --------------------------------------------------------- */}
      {/* BEGIN ------------ Modal for contacting the print manager */}
      <Modal isOpen={contactModal} toggle={toggleContactModal}>
        <ModalHeader toggle={toggleContactModal}>Contact</ModalHeader>
        <ModalBody>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-label">Email Contents</Label>
              <Input
                className="input"
                type="textarea"
                id="emailContents"
                placeholder="Enter your updates ..."
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleEmailSubmit}
            disabled={disabled}
          >
            Send Email
          </Button>
          <Button color="secondary" onClick={toggleContactModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* END ------------ Modal for contacting the print manager */}
      {/* --------------------------------------------------------- */}
      {/* BEGIN ------------ Modal displayed after sending email */}
      <Modal isOpen={emailSentModal} toggle={toggleEmailSentModal}>
        <ModalHeader toggle={toggleEmailSentModal}>Email Sent!</ModalHeader>
        <ModalBody>
          <h2>Email was sent successfully!</h2>
        </ModalBody>
        <ModalFooter>
          <Button className="modal-close" onClick={toggleEmailSentModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* BEGIN ------------ Modal displayed after sending email */}

      {/* Table showing jobs, filtered by status */}
      <DataTable
        columns={columns}
        data={jobList}
        highlightOnHover={true}
        persistTableHead={true}
        striped={true}
        //selectableRows={true}
        onRowClicked={handleRowClick} // call this function when a row is clicked
        customStyles={customStyles}
      />
    </div>
  );
};

export default JobQueueForSpecificUser;
