// src/components/NavBar.js
// the Navigation bar, at the top of each page

// standard react components
import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import { Navbar, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';

// css for the NavBar component
import './NavBar.css';

const NavBar = () => {
  // Auth0 stuff, to customize the view, depending on if the user is logged
  // in (isAuthenticated = true) or not (=false)
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    // Displayed on the top of the screen in every view/component
    <div>
      <Navbar className="NavBar">
        <Nav>
          {/* Always show Home link whether user logged in or not */}
          <Link className="link" to="/">
            Home
          </Link>

          {/* Always show About link whether user logged in or not */}
          <Link className="link" to="/about">
            About
          </Link>
        </Nav>
        <Nav>
          {/* Always show Log In/Out button, but changes text on button whether user logged in or not */}
          {/* If not logged in, provide Log In button */}
          {!isAuthenticated && (
            <div>
              <button
                className="button-link"
                color="secondary"
                onClick={() => loginWithRedirect({})}
              >
                Log In
              </button>
            </div>
          )}
          {/* If logged in, provide Log Out button */}
          {isAuthenticated && (
            <div>
              <button
                className="button-link"
                color="secondary"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Log Out
              </button>
            </div>
          )}
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavBar;
