import React, { Component } from "react";
import testImage from "../assets/test_image.png";

class TestGetImage extends Component {
  constructor(props) {
    super(props);

    // Initialize count state
    this.state = { height: null, width: null, isIncrease: false };

    // Bind context of 'this'
    this.handleClick = this.handleClick.bind(this);

    // Create reference of DOM object
    this.imgRef = React.createRef();
  }

  handleClick = () => {
    // Fetching current height and width
    const height = this.imgRef.current.clientHeight;
    const width = this.imgRef.current.clientWidth;
    alert(` 
      Height : ${height} 
      Width : ${width} 
      `);
  }

  render() {
    return (
      <div>
        {/* Assign reference to DOM element*/}
        <img
          ref={this.imgRef}
          src={testImage}
          alt="test"
        />
        <div>
          <button onClick={this.handleClick}>Fetch dimension</button>
        </div>
      </div>
    );
  }
}
export default TestGetImage;
