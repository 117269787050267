/**
 * @file PleaseLogin.js
 * @description Default page if there is an issue with the URL
 */

// standard react components
import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';

// import css & logo
import './PleaseLogin.css';
import logo from '../assets/AU_library_logo.png';

const PleaseLogin = () => {
  // bring in the Auth0 stuff we need
  const { loading, loginWithRedirect } = useAuth0();

  // if Auth0 tells us it is loading, or if we don't get a user object
  if (loading) {
    return <div>Loading...</div>;
  }

  // ready to render the information from the user object
  return (
    <div className="PleaseLogin">
      {/* <img className="logo" src={logo} alt="Anderson University Logo"></img> */}

      <h1>Please Login</h1>
      <button
        className="button-link"
        color="secondary"
        onClick={() => loginWithRedirect({})}
      >
        Log In
      </button>
    </div>
  );
};

export default PleaseLogin;
