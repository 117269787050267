/**
 * @file front_end/src/services/general.js
 * Some functions that are used in several areas of the app
 */

/**
 * function to return a list of labels, such as "Scholar's Day" etc
 * @return a list of strings
 */
export const getLabels = () => {
  return [
    'Academic Honors Day',
    'AU MarComm',
    'Graphic Design Senior Project',
    'Personal Print',
    "Scholar's Day Poster",
    'Other',
  ];
};

/**
 * function to return a list of status
 * @return a list of strings
 */
export const getStatus = () => {
  return [
    'Submitted',
    'In Process',
    'Waiting On Submitter',
    'Complete - Ready for Pickup',
  ];
};

/**
 * function to return a list of payment methods
 * @return a list of strings
 */
export const getPayments = () => {
  return [
    'Cash or Check',
    'Account',
  ];
};

/**
 * Format the "updated at" date in a reasonable form for display
 * where the passed value is an entire entry (row)
 * @param thisRow the whole row of the database as an object
 * @return the formatted date
 */
export const formatDate = (thisRow) => {
  const thisDate = new Date(thisRow.updatedAt);
  return thisDate.toDateString();
};

/**
 * Format the "updated at" date in a reasonable form for display
 * where the passed value is a plain old date (as a String)
 * @param thisDate the date, as a string
 * @return the formatted date
 */
export const formatDateString = (thisDate) => {
  const newDate = new Date(thisDate);
  return newDate.toDateString();
};
