// src/components/About.js
// The about page for the web app

// standard imports
import React from 'react';

// for this page
import './AboutPage.css';
import logo from '../assets/AU_library_logo.png';

const AboutPage = () => {
  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // Displayed upon first rendering of application
      <div className="About">
        <p>
          This web app was developed in Dr. Coy's Capstone II class throughout
          the Fall of 2020, by Apekshya Koirala, Benjamin Mumma, and Matthew
          Millford. Further work was completed in the Spring 2021 Capstone II
          class, by Will Clemens and Quay'Lee Rolle.{' '}
        </p>
        <p>
          Special thanks to Dr. Coy and Brian Schultz for their contributions in
          creating the base application.
        </p>
        <p className="mailtag">
          {' '}
          To get in contact with an Admin with questions or concerns,{' '}
          <a href="mailto:cpsc@anderson.edu"> please click here</a>{' '}
        </p>
    </div>
  );
};

export default AboutPage;
