/**
 * @file front_end/src/components/JobQueue.js
 * List all jobs in the queue
 */
import React, { useState, useEffect } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from 'reactstrap';

// get css
import './JobQueue.css';

import DataTable from 'react-data-table-component';
import { useAuth0 } from '../react-auth0-wrapper';

// to access the base url (dynamic, either localhost or from heroku)
import config from '../auth_config.json';

// need to connect to backend
import axios from 'axios';

// import function from services file
import {
  formatDate,
  getLabels,
  getStatus,
  getPayments,
} from '../services/general';

// for generateing hashes
import md5 from 'md5';

// configure DataTable
// if we match the "selector" to the column header in the database,
// we can just use our response.data.results to fill the table information
// column formatting here: https://github.com/jbetancur/react-data-table-component#columns
const columns = [
  {
    name: 'User Email',
    selector: 'email',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Paper Type',
    selector: 'paperType',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Request Type',
    selector: 'requestType',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Latest Timestamp',
    selector: 'updatedAt',
    sortable: true,
    wrap: true,
    format: (row) => formatDate(row),
  },
];

// formating DataTable
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '1.01em',
      color: 'black',
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '5px',
      borderBottomStyle: 'solid',
    },
  },
  cells: {
    style: {
      wordBreak: 'break-word',
    },
  },
};

const JobQueue = () => {
  // will need to get the access token to send to the backend
  // this token has info about this user (including email)
  const { getTokenSilently } = useAuth0();

  // state variables
  const [jobList, setJobList] = useState([]);
  const [status, setStatus] = useState('all');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [disabledSendBtn, setDisabledSendBtn] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [paperTypes, setPaperTypes] = useState([[]]);

  // list of available paper types
  const requestTypes = getLabels();
  const dropdownStatus = getStatus();
  const payments = getPayments();

  // execute one time, on first component render
  // execute everytime the "status" state is updated
  useEffect(() => {
    getJobListBasedOnStatus({ status }.status);
  }, [status]);

  // execute one time, on first component render
  useEffect(() => {
    getInkConfig();
  }, []);

  /**
   * sets the paperType for the drop downs
   */
  const getInkConfig = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/jobs', send authentication token
      const response = await axios.get(`${config.BASE_URL}/api/inkconfig/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // what do we want to showToast to the user
      let inkConfig = response.data.results[0];
      let pt = [
        ['Bond', inkConfig.bond],
        ['Satin Photo', inkConfig.satinPhoto],
        ['Matte Photo', inkConfig.mattePhoto],
        ['Self Adhesive Poly', inkConfig.selfAdhesivePoly],
        ['Photo Tex', inkConfig.photoTex],
      ];
      setPaperTypes(pt);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getInkConfig() caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * toggles the dropdown open and close
   */
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  /**
   * when the user submits the form, an email is sent with subject/body
   * from the form
   */
  const handleEmailSubmit = async () => {
    setDisabledSendBtn(true);
    const token = await getTokenSilently();

    // construct the send to
    const sendTo = document.querySelector('.input-sentto-email').value;
    const body = document.querySelector('.input-email-body').value;

    // connect to the endpoint and send an email
    const response = await axios.post(
      `${config.BASE_URL}/api/sendEmail`,
      {
        name: 'Nic Media Poster',
        email: `${sendTo}`,
        subject: 'Poster Job Complete',
        messageHTML: `${body}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // after we send the email, let the user know if it succeeded
    let statusCheck = response.data.gmail;
    if (statusCheck.substr(0, statusCheck.indexOf(' ')) === 'Success') {
      toggleModalEmail();
      setDisabledSendBtn(false);
    } else {
      alert('Oops, something went wrong. Please try again.');
    }
  };

  /**
   * a function that sets the status from the dropdown
   * sets the state variable status
   * @param myStatus the new status
   */
  const updateStatus = (myStatus) => {
    setStatus(myStatus);
  };

  /**
   * a function that is triggered when the user clicks on a row
   * @param {*} row
   */
  const handleRowClick = (row) => {
    console.log(row.filename);
    setSelectedRow(row);
    toggleModal();
  };

  /**
   * a function to connect to the backend
   * sets the state variable jobListWithSpecificStatus
   * @param statusParam the string indicating status, such as "submitted"
   */
  const getJobListBasedOnStatus = async (statusParam) => {
    try {
      // get the token
      const token = await getTokenSilently();
      let response = null; // to store the response object

      // determine if we want all of them or just some of them
      if (statusParam === 'all') {
        // connect to the endpoint 'api/jobs', send authentication token
        response = await axios.get(
          `${config.BASE_URL}/api/not_status/` + 'Complete - Ready for Pickup',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        // connect to the endpoint 'api/status', send authentication token
        response = await axios.get(`${config.BASE_URL}/api/status/` + status, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      // what do we want to show to the user
      setJobList(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('StatusList caught error:' + error);
    }
  };

  /**
   * a function that updates a job in the database
   * @param {object} job the job object to be updated
   */
  const updateJob = async (job) => {
    try {
      const token = await getTokenSilently();

      // connect to the endpoint "/api/jobs"
      await axios.put(
        `${config.BASE_URL}/api/jobs`,
        {
          job,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      checkIfComplete(job);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('Caught error:' + error);
    }
  };

  /**
   * toggles the modal's display
   * if the modal is closing refetch the data
   */
  const toggleModal = () => {
    if (modal) {
      getJobListBasedOnStatus({ status }.status);
    }
    setModal(!modal);
  };

  /**
   * check if job was completed open email modal if so
   */
  const checkIfComplete = (job) => {
    if (
      job.status === 'Complete - Ready for Pickup' ||
      job.status === 'Waiting On Submitter'
    ) {
      setModalEmail(!modalEmail);
    }
  };

  /**
   * toggles the modal's display
   * if the modal is closing refetch the data
   */
  const toggleModalEmail = () => {
    setModalEmail(!modalEmail);
  };

  /**
   * handles actions for when save is clicked
   */
  const hangleSaveClick = async () => {
    const token = await getTokenSilently();
    let job = buildJobObj();
    let input = document.querySelector('.input-file-uplaod');
    if (input.files.length === 1) {
      const file = generateFile(job.email, input.files[0]);

      job.filename = file.name;

      let formData = new FormData();

      formData.append('image', file);

      await axios({
        method: 'post',
        url: `${config.BASE_URL}/api/files/upload`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    await updateJob(job);
    toggleModal();
  };

  /**
   * builds a job from the input boxes
   * with the values in the edit modal's text boxes
   * @return {object} the job to be updated/created
   */
  const buildJobObj = () => {
    let job = {}; // new job object
    let inputs = document.querySelectorAll('.input'); // get all input boxes

    // iterate through each input and update job's meta data
    inputs.forEach((input) => {
      job[input.id] = input.value;
    });

    job = Object.assign(selectedRow, job);

    job.estimateCost = parseFloat(job.estimateCost);

    //these fields are handled by the database we dont need them
    delete job.createdAt;
    delete job.updatedAt;

    return job;
  };

  const handleDownloadImg = () => {
    // set url based on dev (Localhost) or deployment (heroku) -- in auth0_config.json
    let url = `${config.BASE_URL}`;
    let filename = selectedRow.filename;
    window.open(url + '/api/files/' + filename, '_blank');
  };

  /**
   * builds a unique filename
   * @return {string} the filename to be created
   */
  const generateFile = (username, file) => {
    let filename = file.name;
    let id = generateId();

    filename = filename.split('.');
    filename[0] = md5(username + id);
    filename = filename.join('.');

    const renamedFile = new File([file], filename);
    return renamedFile;
  };

  /**
   * https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
   * returns a unique id
   * @return {string} the id
   */
  const generateId = () => {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, (c) => {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  return (
    <div>
      {/* Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Request</ModalHeader>
        <ModalBody>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Email</Label>
              <Input
                className="input"
                type="input"
                id="email"
                placeholder="Enter a email..."
                defaultValue={selectedRow.email}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Filename</Label>
              <Input
                className="input"
                type="input"
                id="filename"
                placeholder="Upload File"
                defaultValue={selectedRow.filename}
                disabled
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Paper Type</Label>
              <Input
                className="input"
                type="select"
                id="paperType"
                placeholder="Enter a paper type..."
                defaultValue={selectedRow.paperType}
              >
                {/* build the list of paper types */}
                {paperTypes.map((eachType) => {
                  return <option key={eachType[0]}>{eachType[0]}</option>;
                })}
              </Input>
            </div>
            <div className="input-container">
              <Label className="input-lable">Request Type</Label>
              <Input
                className="input"
                type="select"
                id="requestType"
                placeholder="Enter a request type..."
                defaultValue={selectedRow.requestType}
              >
                {/* build the list of request types */}
                {requestTypes.map((eachRequest) => {
                  return <option key={eachRequest}>{eachRequest}</option>;
                })}
              </Input>
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Payment Method</Label>
              <Input
                className="input"
                type="select"
                id="paymentMethod"
                placeholder="Enter a payment method..."
                defaultValue={selectedRow.paymentMethod}
              >
                {/* build the list of payment types */}
                {payments.map((eachPayment) => {
                  return <option key={eachPayment}>{eachPayment}</option>;
                })}
              </Input>
            </div>
            <div className="input-container">
              <Label className="input-lable">Account Details</Label>
              <Input
                className="input"
                type="input"
                id="accountDetails"
                placeholder="Enter a Account Details..."
                defaultValue={selectedRow.accountDetails}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Status</Label>
              <Input
                className="input"
                type="select"
                id="status"
                placeholder="Enter a status..."
                defaultValue={selectedRow.status}
              >
                {/* build the list of status */}
                {dropdownStatus.map((eachStatus) => {
                  return <option key={eachStatus}>{eachStatus}</option>;
                })}
              </Input>
            </div>
            <div className="input-container">
              <Label className="input-lable">Estimate Cost</Label>
              <Input
                className="input"
                type="input"
                id="estimateCost"
                placeholder="Enter estimate cost..."
                defaultValue={parseFloat(selectedRow.estimateCost).toFixed(2)}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Nickname</Label>
              <Input
                className="input"
                type="input"
                id="nickname"
                placeholder="Enter a nickname..."
                defaultValue={selectedRow.nickname}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Input
            className="input-file-uplaod"
            type="file"
            id="fileupload"
            placeholder="Upload File"
          />
          <Button className="download-img-btn" onClick={handleDownloadImg}>
            Download Img
          </Button>
          <Button color="primary" onClick={hangleSaveClick}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* email Modal */}
      <Modal isOpen={modalEmail} toggle={toggleModalEmail}>
        <ModalHeader toggle={toggleModalEmail}>Email</ModalHeader>
        <ModalBody>
          <div className="input-group">
            <div className="input-container-full">
              <Label className="input-lable">User's Email</Label>
              <Input
                className="input input-sentto-email"
                type="input"
                id="userEmail"
                placeholder="Enter a email..."
                defaultValue={selectedRow.email}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container-full">
              <Label className="input-lable">Email Body</Label>
              <Input
                className="input-email-body"
                type="textarea"
                id="emailBody"
                placeholder="Enter a body..."
                defaultValue={
                  selectedRow.email +
                  '\n' +
                  selectedRow.filename +
                  '\n' +
                  selectedRow.paperType +
                  '\n' +
                  selectedRow.requestType +
                  '\n' +
                  selectedRow.paymentMethod +
                  '\n' +
                  selectedRow.accountDetails
                }
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleEmailSubmit}
            disabled={disabledSendBtn}
          >
            Send
          </Button>
          <Button color="secondary" onClick={toggleModalEmail}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dropdown menu showing status */}
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>Filter By Status</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => updateStatus('all')}>
            All Jobs
          </DropdownItem>
          <DropdownItem onClick={() => updateStatus('Submitted')}>
            Submitted
          </DropdownItem>
          <DropdownItem onClick={() => updateStatus('In Process')}>
            In Process
          </DropdownItem>
          <DropdownItem onClick={() => updateStatus('Waiting On Submitter')}>
            Waiting On Submitter
          </DropdownItem>
          <DropdownItem
            onClick={() => updateStatus('Complete - Ready for Pickup')}
          >
            Complete - Ready for Pickup
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {/* Table showing jobs, filtered by status */}
      <DataTable
        columns={columns}
        data={jobList}
        highlightOnHover={true}
        persistTableHead={true}
        striped={true}
        //selectableRows={true}
        onRowClicked={handleRowClick} // call this function when a row is clicked
        customStyles={customStyles}
      />
    </div>
  );
};

export default JobQueue;
