// src/components/Home.js
// The first landing page for the app

// standard imports
import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

// for this page
import './Home.css';
import logo from '../assets/AU_logo.png';
import TestGetImage from './TestGetImage';
import TestGetPDFDimensions from './TestGetPDFDimensions';

const Home = () => {
  /* Auth0 stuff, to customize the view, depending on if the user is
   * logged in (isAuthenticated = true) or not (=false) */
  const { isAuthenticated, user } = useAuth0();
  let isManager = false;
  if (isAuthenticated) {
    isManager = user['http://cel.anderson.edu/nicmediaposter/roles'].includes(
      'manager'
    );
  }

  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // Displayed upon first rendering of application
    <div className="Home">
      <div className="row">
        <div className="col-header">NicMedia Poster Printing</div>
      </div>
      <div className="logged-in">
        {/* if we are not logged in, display this text and the logo */}
        {isAuthenticated === false && (
          <div>
            <div className="logo">
              <img src={logo} alt="Anderson University Logo"></img>
              <br></br>
              <span className="span-text-size">Please login to continue.</span>
            </div>
          </div>
        )}

        {/* if we are logged in as a user, show the request new print and current request button.*/}
        {isAuthenticated && (
          <div>
            {
              <span className="span-text-size">
                Hello {user.name}! You are logged in.
              </span>
            }
          </div>
        )}
        <div>
          {/* if we are logged in as a manager, show the request new print, current request button, and view queue button*/}
          {isManager && (
            <Row>
              <Link to="/queue">
                <Col>
                  <button className="buttonViewQueue">
                    View Request Queue
                  </button>
                </Col>
              </Link>
            </Row>
          )}
          {/* if we are logged in as a user, show the request new print and current request button.*/}
          {isAuthenticated && (
            <React.Fragment>
              <Row>
                <Link to="/newrequest">
                  <Col>
                    {' '}
                    <button className="buttonPrintRequest">
                      Submit a print request
                    </button>
                  </Col>
                </Link>
              </Row>
              <Row>
                <Link to="/myrequests">
                  <Col>
                    {' '}
                    <button className="buttonCurrentRequests">
                      View current request
                    </button>
                  </Col>
                </Link>
              </Row>
            </React.Fragment>
          )}

          {/* if we are logged in as a manager, show the request new print, current request button, and view queue button*/}
          {isManager && (
            <Row>
              <Link to="/inkconfig">
                <Col>
                  <button className="buttonViewInkConfig">
                    Config Ink Prices
                  </button>
                </Col>
              </Link>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
